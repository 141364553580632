<template>
  <v-toolbar
    :dark="dark"
    :rounded="rounded"
    :color="dialogTitleColorClass"
    :dense="dense"
    :elevation="elevation"
    v-on="$listeners"
    v-bind="$attrs"
  >
    <!-- toolbar avatar -->
    <v-avatar v-if="iconName">
      <v-icon>{{ iconName }}</v-icon>
    </v-avatar>

    <!-- toolbar title -->
    <v-toolbar-title>
      <slot name="default">{{ title }}</slot>
    </v-toolbar-title>

    <!-- image gradient -->
    <template v-slot:img="{ props }" v-if="gradient">
      <v-img v-bind="props" :gradient="gradient"> </v-img>
    </template>
  </v-toolbar>
</template>

<script>
import { dialogToolbarSettingsMixin } from "@/mixins/shared/base/settings/dialogToolbarSettingsMixin";
import { toolbarMixin } from "@/mixins/shared/base/toolbar/toolbarMixin";

export default {
  inheritAttrs: false,
  name: "BaseDialogToolbar",
  mixins: [toolbarMixin, dialogToolbarSettingsMixin],
  computed: {
    /**
     * Overwrites toolbarMixin.toolbarColor
     * @returns {{name: String, variantType: String, variantNumber: Number}}
     */
    toolbarColor() {
      return this.dialogTitleColor;
    }
  }
};
</script>
